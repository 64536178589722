.umt--button {
  width: 500px !important;
  max-width: 700px !important;
  padding: 20px;
  margin: 5px;
}

.umt--modal {
  padding-right: 0% !important;
  padding-left: 0%;
  text-align: center;
}

.umt--div-child {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  display: inline-block;
}