@import './mixins.scss';
@import './overrides.scss';

.landing-page__illo {
  max-width: 100%;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  @include landing-page-background;
}

.landing-page__heading {
  @include carbon--type-style('productive-heading-05');
}

.landing-page__r2 {
  margin-top: rem(-40px);
}

.landing-page__tab-content {
  padding-top: $layout-05;
  padding-bottom: $layout-05;
}

.landing-page__subheading {
  @include carbon--type-style('productive-heading-03');
  @include carbon--font-weight('semibold');
}

.landing-page__p {
  // @include carbon--type-style('productive-heading-03');
  // margin-top: $spacing-06;
  // margin-bottom: $spacing-08;

  // @include carbon--breakpoint-between((320px + 1), md) {
  //   max-width: 75%;
  // }
  @include carbon--font-weight('light');
}

.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  @include landing-page-background;
}

.landing-page__label {
  @include carbon--type-style('heading-01');
}

.landing-page__button {
  max-width: 500px !important;
  margin-top: 5%;
}
.landing-page__button-login {
  max-width: 500px !important;
  width: 50% !important;
}

.landing-page__button-set {
  width: inherit !important;
  display: block !important;
}

.landing-page__image {
  max-width: 100%;
  height: auto;
  width: auto\9;
}

.landing-page__grid {
  max-height: 100vmin;
  position: relative;
  overflow: hidden !important;
}

.landing-page__content {
  max-height: 100vmin;
  position: relative;
  overflow: hidden !important;
}
